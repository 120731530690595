import React from "react";
import Timer from "./Timer.js";
import beep from "../../../audio/beep.mp3";
import "../Tools.scss"
 
export default class Pamadoro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: "25:00",
      pause: false,
      interval: null,
      running: false,
      startbtn: "success",
      seconds: 25 * 60,
    };
    this.setPaus = this.setPaus.bind(this);
    this.setPomodoro = this.setPomodoro.bind(this);
    this.handlePomodoro = this.handlePomodoro.bind(this);
    this.startCountdown = this.startCountdown.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  setPaus() {
    this.setState({ time: "5:00", seconds: 5 * 60 });
  }
  setPomodoro() {
    this.setState({ time: "25:00", seconds: 25 * 60 });
  }

  handlePomodoro() {
    if (this.state.running === true) {
      //stop
      this.setState({ startbtn: "success", running: false });
      document.getElementById("start").innerHTML = "Start";
      clearInterval(this.state.interval);
      this.setState({ interval: null });
      if (this.state.pause) {
        this.setPaus();
      } else {
        this.setPomodoro();
      }
    } else {
      //start
      this.setState({ startbtn: "danger", running: true });
      document.getElementById("start").innerHTML = "Stopp";
      this.setState({ interval: setInterval(this.startCountdown, 1000) });
    }
  }

  startCountdown() {
    var timer = this.state.seconds - 1;
    var minutes, seconds;
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    this.setState({ time: minutes + ":" + seconds, seconds: timer });
    if (--timer < 0) {
      var x = document.getElementById("audio");
      x.play();
      this.handlePomodoro();
    }
  }

  render() {
    return (
      <div className="pomodoro-card">
        <audio id="audio">
          <source src={beep} type="audio/mpeg" />
        </audio>
        <h1>Pomodoro</h1>
          <button variant="secondary" onClick={this.setPomodoro}>
            Pass
          </button>
          <button variant="secondary" onClick={this.setPaus}>
            Paus
          </button>
        <Timer time={this.state.time} />
        <button
          id="start"
          variant={this.state.startbtn}
          className="btn-start"
          onClick={this.handlePomodoro}
        >
          Start
        </button>
      </div>
    );
  }
}
