import React from "react";
import { graphql } from "gatsby";
import PomodoroCard from "../../components/Tools/Pomodorotekniken/PomodoroCard";
import Page from "../../components/Page";
import { GatsbyImage } from "gatsby-plugin-image"

export default function Pomodorotekniken({ data }) {
  const image = data.image.childImageSharp.gatsbyImageData;

  const title = "Pomodoro Timer - Plugga effektivt"
  
  return (
    <>
      <Page title={title} description="Använd vår Pomodoro timer när du ska plugga till nästa tenta eller prov. Gör det enkelt för dig att komma igång med Pomodoro metoden.">
        <div className="container px-5 py-6 mx-auto">
          <article className="blogPost">
            {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="målsättning" />}
            <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">{title}</h1>
 
            <p>
              Pomodoro-metoden är en mycket vanlig teknik för öka din
              produktivitet, som du hört talas om tidigare. Oavsett om du hört
              talas om den eller inte så innebär Pomodoro-tekniken att du arbetar
              fokuserat på en uppgift i 25 minuter utan distraktioner, följt av
              en paus på 5 minuter. Efter fyra
              Pomodoro's tar du sedan en lite längre paus på minst 15-30
              minuter innan du återgår till arbetet igen. Nedan kan du använda dig av vår timer.
            </p>
            <PomodoroCard />
            <br />
            <h2>Pomodoro metoden består av följande steg:</h2>
            <p>Nedan går vi igenom de olika stegen för Pomodoro metoden mer ingående.</p>
            <h3>1. Välj ut en arbetsuppgift som du vill fokusera på</h3>
            <p>Sätt en timer på 25 minuter. Det är tiden som du ska ha full fokus på uppgiften. Innan du börjar, försök att minimera alla distraktioner genom att lägga bort mobiltelefonen och stänga av notiser. Ett tips är dessutom att stänga ner alla flikar på datorn som du inte kommer använda dig av. Du använda dig av din egna timer på din telefon eller använda vår egen timer.</p>
            <h3>2. Dags för en paus</h3>
            <p>När timern ringer är det dags för en paus. Avsluta direkt som du arbetar, även om du självklart får fortsätta om du är inte i ett flow. När det är paus har du tid att sträcka på dig en stund, kanske ta en ny kopp kaffe eller kolla mailen en stund. Pausen skall vara på 5 minuter.</p>
            <h3>3. Påbörja en ny pomodoro, och upprepa igen</h3>
            <p>Upprepa steg 1 och 2 igen, och gör detta i 4 gånger. Därefter är det bra att ta en lite längre paus på omkring 15-30 minuter. Använd tiden för att vila en stund eller kanske ta något att äta. Efter din längre paus kan du påbörja ännnu en ny Pomodoro-session, och börja om igen från början. </p>
            <p>Pomodoro-metoden är en teknik som gör det lättare att bibehålla fokus under ett längre tidsintervall genom att du tar många korta pauser. När du utför en Pomodoro är det även viktigt att komma ihåg att att försöka eliminera alla störmoment så att det blir så lätt som möjligt för dig att koncentrera dig på arbetet.</p>
            <br />
          </article>

        </div>
      </Page>
    </>
  );
}


export const query = graphql`
  query {
    image: file(relativePath: { eq: "feynman.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
