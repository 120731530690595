import React from "react";

export default class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  render() {
    return (
      <h1 className="time-text" id="time">
        {this.props.time}
      </h1>
    );
  }
}